var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g({tag:"component",staticClass:"ax-tile",class:{
    'ax-tile--outlined': _vm.outlined,
    'ax-tile--show-overflow': _vm.showOverflow,
  },style:({
    backgroundColor: (!_vm.outlined && _vm.computedColor) || _vm.computedBG,
    borderColor: _vm.outlined && _vm.computedColor,
    maxHeight: _vm.maxHeight,
  })},_vm.$listeners),[(_vm.$slots.title)?_c('div',{staticClass:"ax-tile__title"},[_vm._t("title")],2):_vm._e(),(_vm.$slots.content)?_c('div',{staticClass:"ax-tile__content"},[_vm._t("content")],2):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }