/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref } from '@vue/composition-api';

interface UsePaginationProps {
  currentPage?: number;
  perPage: number;
  items: any[];
}

/**
 * Adds pagination capabilities to a component.
 */
export const usePagination = (props: UsePaginationProps) => {
  const currentPageRef = ref(props.currentPage || 1);
  const itemsPerPage = ref(props.perPage || 10);

  const pageCount = computed(() =>
    Math.ceil(props.items.length / itemsPerPage.value),
  );

  const paginatedItems = computed(() => {
    // itemsPerPage of -1 is used to display all items
    if (itemsPerPage.value === -1) {
      return props.items;
    }
    const end = currentPageRef.value * itemsPerPage.value;
    return props.items.slice(end - itemsPerPage.value, end);
  });

  const paginationNext = (): void => {
    if (currentPageRef.value < pageCount.value) {
      currentPageRef.value += 1;
    }
  };
  const paginationPrev = (): void => {
    if (currentPageRef.value > 0) {
      currentPageRef.value -= 1;
    }
  };

  return {
    currentPage: currentPageRef,
    itemsPerPage,
    pageCount,
    paginatedItems,
    paginationNext,
    paginationPrev,
  };
};
