var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$scopedSlots['actions-left'] ||
      _vm.$scopedSlots['actions-right'] ||
      _vm.columnSelect
    )?_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"tw-flex-grow",attrs:{"cols":"auto"}},[_vm._t("actions-left")],2),_c('v-col',{attrs:{"cols":"auto"}},[_vm._t("actions-right"),(_vm.columnSelect)?_c('column-selector',{attrs:{"all-columns":_vm.headers,"static-columns":_vm.staticHeaders},model:{value:(_vm.activeTableHeaders),callback:function ($$v) {_vm.activeTableHeaders=$$v},expression:"activeTableHeaders"}}):_vm._e()],2)],1):_vm._e(),_c('v-data-table',_vm._g(_vm._b({key:_vm.tableKey,staticClass:"ax-table",class:{
      'ax-table--bordered': _vm.hasBorder,
      'ax-table--all-expanded': _vm.allExpanded,
    },attrs:{"height":(_vm.keepInView && _vm.items.length > 5 && _vm.tableHeight) || undefined,"must-sort":_vm.forcedSort,"headers":_vm.activeTableHeaders,"fixed-header":_vm.fixedHeader || _vm.keepInView,"footer-props":_vm.tableFooterProps,"expanded":_vm.allExpanded ? _vm.items : _vm.expanded,"options":_vm.options,"page":_vm.options && _vm.options.page ? _vm.options.page : 1},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.tableProps,false),Object.assign({}, _vm.$listeners,
      {'update:options': _vm.updateOptions})),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }